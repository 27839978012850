.cardBack{
    position: absolute;
    height: 130px;
    top: 500px;
    left: 400px;
}

.cardBar{
    position: relative;
    height: 130px;
    width: 50%;
}

@media (max-width: 450px) {
    .cardBack{
        position: absolute;
        height: 60px;
        top: 500px;
        left: 400px;
    }

    .cardBar{
        position: relative;
        height: 60px;
        width: 50%;
    }
}
