.ButtonBox{
    position: absolute;
    background-color: azure;
    border-radius: 25px;
    width: 400px;
    height: 250px;
    left: 880px;
    top: 660px;
    
}

.textClass{
    /* font-family: Verdana,Geneva,sans-serif;  */
    font-family: arcadeclassic;
    text-align: center;
    word-spacing: 5px;
    font-size: 23px;
    margin-top: 15px;
    top: 50px;
}

.textClassPlay{
    /* font-family: Verdana,Geneva,sans-serif;  */
    font-family: arcadeclassic;
    text-align: center;
    word-spacing: 5px;
    font-size: 40px;
    margin-top: 80px;
    top: 50px;
}

.ButtonStyle{
    position: absolute;
    top: 190px;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    font-size: 20px;
    /* font-family: Verdana,Geneva,sans-serif;  */
    font-family: arcadeclassic;
}

.ButtonStyleSuit{
    position: absolute;
    top: 150px;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    font-size: 20px;
    /* font-family: Verdana,Geneva,sans-serif;  */
    font-family: arcadeclassic;
}

.passButton{
    position: absolute;
    top: 130px;
    left: 135px;
    width: 120px;
    height: 40px;
    border-radius: 10px;
    font-size: 20px;
    /* font-family: Verdana,Geneva,sans-serif;  */
    font-family: arcadeclassic;
}
.dealButton{
    position: absolute;
    left: 120px;
    top: 110px;
    width: fit-content;
    /* font-family: Verdana,Geneva,sans-serif;  */
    font-family: arcadeclassic;
    font-size: 3.5rem;
    border-radius: 15px;
}

.passButton2{
    position: absolute;
    left: 25px;
    top: 180px;
    width: fit-content;
    /* font-family: Verdana,Geneva,sans-serif;  */
    font-family: arcadeclassic;
    font-size: 2.5rem;
    border-radius: 15px;
    word-spacing: 10px;
}

.confirmButton{
    position: absolute;
    left: 100px;
    top: 155px;
    width: fit-content;
    /* font-family: Verdana,Geneva,sans-serif;  */
    font-family: arcadeclassic;
    font-size: 3rem;
    border-radius: 15px;
}

.confirmButtonPressed{
    position: absolute;
    left: 80px;
    top: 155px;
    width: fit-content;
    /* font-family: Verdana,Geneva,sans-serif;  */
    font-family: arcadeclassic;
    font-size: 3rem;
    border-radius: 15px;
}

@media (max-width: 450px) {

    .ButtonBox{
        position: absolute;
        background-color: azure;
        border-radius: 25px;
        width: 350px;
        height: 120px;
        left: 15px;
        top: 150px;
        
    }

    .textClass{
        /* font-family: Verdana,Geneva,sans-serif;  */
        font-family: arcadeclassic;
        text-align: center;
        word-spacing: 3px;
        font-size: 0.9rem;
        margin-top: 4px;
        top: 50px;
    }

    .textClassPlay{
        /* font-family: Verdana,Geneva,sans-serif;  */
        font-family: arcadeclassic;
        text-align: center;
        word-spacing: 5px;
        font-size: 30px;
        margin-top: 30px;
        top: 10px;
    }

    .ButtonStyle{
        position: absolute;
        top: 80px;
        width: 30px;
        height: 30px;
        border-radius: 10px;
        font-size: 15px;
        /* font-family: Verdana,Geneva,sans-serif;  */
        font-family: arcadeclassic;
        color: black;
        margin: auto;
    }

    .ButtonStyleSuit{
        position: absolute;
        top: 70px;
        width: 35px;
        height: 35px;
        border-radius: 10px;
        font-size: 16px;
        /* font-family: Verdana,Geneva,sans-serif;  */
        font-family: arcadeclassic;
        color: black;
        margin: auto;
    }

    .passButton{
        position: absolute;
        top: 50px;
        left: 135px;
        width: 80px;
        height: 25px;
        border-radius: 10px;
        font-size: 15px;
        /* font-family: Verdana,Geneva,sans-serif;  */
        font-family: arcadeclassic;
        color: black;
    }
    .dealButton{
        position: absolute;
        left: 110px;
        top: 60px;
        width: fit-content;
        /* font-family: Verdana,Geneva,sans-serif;  */
        font-family: arcadeclassic;
        font-size: 2.7rem;
        border-radius: 15px;
        color: black;
    }

    .passButton2{
        position: absolute;
        left: 80px;
        top: 88px;
        width: fit-content;
        /* font-family: Verdana,Geneva,sans-serif;  */
        font-family: arcadeclassic;
        font-size: 1.2rem;
        border-radius: 15px;
        word-spacing: 5px;
        color: black;
    }

    .confirmButton{
        position: absolute;
        left: 100px;
        top: 70px;
        width: fit-content;
        /* font-family: Verdana,Geneva,sans-serif;  */
        font-family: arcadeclassic;
        font-size: 2rem;
        border-radius: 15px;
        color: black;
    }

    .confirmButtonPressed{
        position: absolute;
        left: 100px;
        top: 70px;
        width: fit-content;
        /* font-family: Verdana,Geneva,sans-serif;  */
        font-family: arcadeclassic;
        font-size: 2rem;
        border-radius: 15px;
    }
}