.WinnerBox{
    position: absolute;
    background-color: azure;
    border-radius: 25px;
    width: 400px;
    height: 250px;
    left: 380px;
    top: 330px;
    z-index: 10;
}

.WinnerText{
    font-family: arcadeclassic;
    text-align: center;
    font-size: 2rem;
    word-spacing: 10px;
    margin-top: 20px;
}

.iconWrapper1{
    position: absolute;
    left: 120px;
}

.iconWrapper2{
    position: absolute;
    left: 200px;
}

.RestartButton{
    /* font-family: Verdana,Geneva,sans-serif;  */
    font-family: arcadeclassic;
    font-size: 25px;
    border-radius: 12px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: fit-content;
    margin: auto;
    background-color: rgb(80, 197, 76);
    word-spacing: 4px;
}

.RestartDiv{
    position: absolute;
    width: fit-content;
    top: 190px;
    left: 118px;
}

@media (max-width: 450px) {
    .WinnerBox{
        position: absolute;
        background-color: azure;
        border-radius: 25px;
        width: 350px;
        height: 250px;
        left: 20px;
        top: 300px;
        z-index: 10;
    }

    .WinnerText{
        font-family: arcadeclassic;
        text-align: center;
        font-size: 1.5rem;
        word-spacing: 10px;
        margin-top: 20px;
    }

    .iconWrapper1{
        position: absolute;
        top: 80px;
        left: 90px;
    }

    .iconWrapper2{
        position: absolute;
        top: 80px;
        left: 200px;
    }

    .RestartButton{
        /* font-family: Verdana,Geneva,sans-serif;  */
        font-family: arcadeclassic;
        font-size: 25px;
        border-radius: 12px;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        width: fit-content;
        margin: auto;
        background-color: rgb(80, 197, 76);
        word-spacing: 4px;
    }

    .RestartDiv{
        position: absolute;
        width: fit-content;
        top: 190px;
        left: 100px;
    }
}