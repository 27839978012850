.DisconnectBox{
    position: absolute;
    background-color: azure;
    border-radius: 25px;
    width: 400px;
    height: 250px;
    left: 380px;
    top: 330px;
    z-index: 10;
}

.DisconnectText{
    font-family: arcadeclassic;
    text-align: center;
    font-size: 2rem;
    word-spacing: 10px;
    margin-top: 20px;
}

@media (max-width: 450px) {
    .DisconnectBox{
        position: absolute;
        background-color: azure;
        border-radius: 25px;
        width: 350px;
        height: 250px;
        left: 20px;
        top: 300px;
        z-index: 10;
    }

    .DisconnectText{
        font-family: arcadeclassic;
        text-align: center;
        font-size: 1.5rem;
        word-spacing: 10px;
        margin-top: 20px;
    }
}