.cardMine{
    position: absolute;
    height: 130px;
}

.cardBarMine{
    position: relative;
    height: 130px;
    width: 50%;
}

@media (max-width: 450px) {
    .cardMine{
        position: absolute;
        height: 80px;
}

    .cardBarMine{
        position: relative;
        height: 80px;
        width: 50%;
    }

}

