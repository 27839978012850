.chatForm{
    position: absolute;
    width: 75%;
    margin: auto;
    left: 20px;
    bottom: 20px;
}

.inputChat{
    /* font-family: Verdana,Geneva,sans-serif;  */
    font-family: arcadeclassic;
    width: 100%;
    height: 30px;
    font-size: 1.3rem;
    border-radius: 4px;
    border-color: rgb(0, 1, 73);
    word-spacing: 4px;
}

.chatBoxWrapper{
    width: 500px;
    height: 160px;
    position: absolute;
    top: 10px;
    left: 870px;
    border-radius: 30px;
    background-color: rgba(0,0,0,0.3); /* Black background with opacity */
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 65px;
    overflow: hidden;
}

.sendbutton{
    font-family: arcadeclassic;
    width: fit-content;
    height: 30px;
    font-size: 1.rem;
    padding-top: 10px;
    border-radius: 4px;
    border-color: rgb(0, 1, 73);
    margin: auto;
    position: absolute;
    bottom: 25px;
    right: 19px;
}

.sendbutton button{
    font-family: Verdana,Geneva,sans-serif;
    font-size: 1.3rem;
    background-color: rgb(255, 255, 255);
    color: black;
    border: none;
    border-radius: 4px;
    width: 100%;
    margin: 0;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 12px;
    padding-right: 12px;
}

.messageWrapper1{
    /* justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 60px; */
    width: 75%;
    display: inline-block;
    /* height: 100%; */
}

.userNameDiv{
    font-family: arcadeclassic;
    font-size: 1.2rem;
    font-weight: bold;    
    color: black;
    padding-right: 7px;
    /* float: right; */
    padding-top: 0px;
    padding-left: 5px;

}

.messageDiv{
    font-family: arcadeclassic;
    font-size: 1.2rem;
    color: black;
    word-spacing: 4px;
    /* outline: 2px dashed rgb(255, 51, 0); */
}

.userWrapper{
    display: inline-block;
    width: 25%; 
    height: 100%;
    /* outline: 2px dashed blue; */
    vertical-align: top;
}

.lineWrapper{
    /* outline: 2px dashed blue; */
}